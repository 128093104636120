import { keys, isNil, isUndefined, pickBy } from 'lodash'
import { push } from 'connected-react-router'

import { preliminaryErrorHandler } from '../utils/errorHandler'
import { constants } from '../constants'
import {
  mapAPIDataToUIFormat,
  mapUIDataToAPIFormat,
  calculateSuperEventTime,
  createSubEventsFromFormValues,
  updateSubEventsFromFormValues,
  splitSubEvents,
} from '../utils/formDataMapping'
import { emptyField, scrollToTop } from '../utils/helpers'
import { deleteEvent } from '../utils/events'

import { client } from '../api/client'
import { clearFlashMsg, setFlashMsg } from '../store/app/index'
import { setLoading } from '../store/editor'

import { doValidations } from '../validation/validator'
import { getContentLanguages } from '../utils/language'
import { getSuperEventId } from '../utils/events'

const { PUBLICATION_STATUS, SUPER_EVENT_TYPE_RECURRING, EVENT_CREATION } =
  constants

/**
 * Set editor form data
 * @param {obj} formValues      new form values
 */
export function setData(values) {
  return {
    type: constants.EDITOR_SETDATA,
    values,
  }
}

export function updateSubEvent(value, property, eventKey) {
  return {
    type: constants.EDITOR_UPDATE_SUB_EVENT,
    value,
    property,
    eventKey,
  }
}

export function sortSubEvents() {
  return {
    type: constants.EDITOR_SORT_SUB_EVENTS,
  }
}
export function setEventData(values, key) {
  return {
    type: constants.EDITOR_SETDATA,
    key,
    values,
    event: true,
  }
}
export function setOfferData(values, key) {
  return {
    type: constants.EDITOR_SETDATA,
    key,
    values,
    offer: true,
  }
}
export function addOffer(values) {
  return {
    type: constants.EDITOR_ADD_OFFER,
    values,
  }
}
export function deleteOffer(offerKey) {
  return {
    type: constants.EDITOR_DELETE_OFFER,
    offerKey,
  }
}
export function setFreeOffers(isFree) {
  return {
    type: constants.EDITOR_SET_FREE_OFFERS,
    isFree,
  }
}
export function setLanguages(languages) {
  return {
    type: constants.EDITOR_SETLANGUAGES,
    languages,
  }
}

// TODO: Refactor, remove the logic and only return object.
export function validateFor(publicationStatus) {
  if (
    publicationStatus === PUBLICATION_STATUS.PUBLIC ||
    publicationStatus === PUBLICATION_STATUS.DRAFT
  ) {
    return {
      type: constants.VALIDATE_FOR,
      validateFor: publicationStatus,
    }
  }

  return {
    type: constants.VALIDATE_FOR,
    validateFor: null,
  }
}

/**
 * Set validation errors for editor (shown with validation popovers)
 * @param {obj} errors
 */
export const setValidationErrors = (errors) => {
  return { type: constants.SET_VALIDATION_ERRORS, errors }
}

/**
 * Format descriptions to HTML
 * @param formValues
 */
const formatDescription = (formValues) => {
  // eslint-disable-next-line no-constant-binary-expression
  const formattedDescriptions = { ...formValues.description } || {}

  for (const [key, value] of Object.entries(formattedDescriptions)) {
    if (value) {
      const description = value
        .replace(/\n\n/g, '</p><p>')
        .replace(/\n/g, '<br/>')
        .replace(/&/g, '&amp;')
      // check if the value is already wrapped in a <p> tag
      const formattedDescription =
        description.indexOf('<p>') === 0 &&
        description.substring(description.length - 4) === '</p>'
          ? description
          : `<p>${description}</p>`

      formattedDescriptions[key] = formattedDescription
    }
  }

  return formattedDescriptions
}

/**
 * Replace all editor values
 * @param  {obj} formData     new form values to replace all existing values
 */
export function replaceData(formData) {
  return (dispatch, getState) => {
    const { keywordSets } = getState().editor
    let formObject = mapAPIDataToUIFormat(formData, keywordSets)
    const publicationStatus =
      formObject.publication_status || PUBLICATION_STATUS.PUBLIC

    // run only draft level validation before copying
    const validationErrors = doValidations(
      formObject,
      getContentLanguages(formObject),
      PUBLICATION_STATUS.DRAFT,
      keywordSets
    )

    // empty id, event_status, and any field that has validation errors
    keys(validationErrors).map((field) => {
      formObject = emptyField(formObject, field)
      return formObject
    })
    delete formObject.id
    delete formObject.event_status
    delete formObject.super_event_type
    formObject.sub_events = {}

    // here, we do more thorough validation
    dispatch(validateFor(publicationStatus))
    dispatch(setValidationErrors({}))
    dispatch({
      type: constants.EDITOR_REPLACEDATA,
      values: formObject,
    })
    return undefined
  }
}

/**
 * Clear all editor form data
 */
export const clearData = () => ({ type: constants.EDITOR_CLEARDATA })

/**
 * Prepares and validates the form values
 * @param formValues        Form data
 * @param contentLanguages  Form languages
 * @param updateExisting    Whether we're updating an existing event
 * @param publicationStatus Publication status
 * @param dispatch
 * @param keywordSets       Keyword sets that are passed to the validator, so that we can validate against them
 * @returns {{}|*}
 */
const prepareFormValues = (
  formValues,
  contentLanguages,
  updateExisting,
  publicationStatus,
  dispatch,
  keywordSets,
  secondTime = false
) => {
  // exclude all existing sub events from editing form
  const formValues2 = {
    ...formValues,
    sub_events: updateExisting
      ? pickBy(formValues.sub_events, (event) => !event['@id'])
      : formValues.sub_events,
  }

  let recurring = formValues.super_event_type != null
  if (formValues2.sub_events) {
    recurring =
      recurring || (keys(formValues2.sub_events).length > 0 && !secondTime)
  }

  const cleanedFormValues = { ...formValues2 }
  // Run validations
  const validationErrors = doValidations(
    cleanedFormValues,
    contentLanguages,
    publicationStatus,
    keywordSets
  )

  // There are validation errors, don't continue sending
  if (keys(validationErrors).length > 0) {
    dispatch(setLoading(false))
    dispatch(setValidationErrors(validationErrors))
    return undefined
  }

  let data = {
    ...cleanedFormValues,
    publication_status: publicationStatus,
    description: formatDescription(cleanedFormValues),
  }

  let shouldRecalculateSuperEventTime = recurring

  // specific processing for event with multiple dates
  if (shouldRecalculateSuperEventTime) {
    // calculate the super event's start_time and end_time based on its sub events
    const superEventTime = calculateSuperEventTime(
      pickBy(formValues.sub_events, (event) => !event.markAsDeleted)
    )
    data = {
      ...data,
      super_event_type: SUPER_EVENT_TYPE_RECURRING,
      start_time: superEventTime.start_time,
      end_time: superEventTime.end_time,
    }
  }

  return mapUIDataToAPIFormat(data)
}

/**
 *
 * @param createdEventId
 * @param data
 * @param action
 */
export const sendDataComplete =
  (createdEventId, data, action) => (dispatch) => {
    if (data.apiErrorMsg) {
      dispatch(setFlashMsg(data.apiErrorMsg, 'error', data))
    } else {
      dispatch(push(`/event/done/${action}/${createdEventId}`))
      dispatch({ type: constants.EDITOR_SENDDATA_SUCCESS })
      scrollToTop()
    }
    dispatch(setLoading(false))
  }

/**
 * Sends the prepared form values to the backend
 * @param {import('../store/editor').EditorValues
 * | { [key: number]: import('../store/editor').EditorValues}[]} formValues Form data
 * @param {boolean} updateExisting Whether we're updating an existing event
 * @param {'draft' | 'public'} publicationStatus Publication status
 * @param {import('../types/apiTypes').APIEvent[]
 * | { [key: number]: import('../store/editor').EditorValues}[] | null} subEvents Sub event data
 * @param {boolean?} updatingSubEvents Whether we're updating sub events
 * @returns {Promise<Function>}
 */
export const executeSendRequest =
  (
    formValues,
    updateExisting,
    publicationStatus,
    subEvents,
    updatingSubEvents = false,
    secondTime = false,
    last = true
  ) =>
  async (dispatch, getState) => {
    // get needed information from the state
    const { keywordSets, contentLanguages } = getState().editor
    let publicationStatus2 = publicationStatus
    let isLast = last

    // check publication status to decide whether to allow the request to happen
    publicationStatus2 = publicationStatus2 || formValues.publication_status

    if (!publicationStatus2 || !formValues) {
      return
    }

    dispatch(setLoading(true))
    dispatch(validateFor(publicationStatus2))

    // prepare the body of the request (event object/array)
    let preparedFormValues
    if (!Array.isArray(formValues)) {
      preparedFormValues = prepareFormValues(
        formValues,
        contentLanguages,
        updateExisting,
        publicationStatus2,
        dispatch,
        keywordSets,
        secondTime
      )

      if (!preparedFormValues || !keys(preparedFormValues).length > 0) {
        return
      }
    } else if (Array.isArray(formValues) && formValues.length > 0) {
      preparedFormValues = formValues
        .map((formObject) =>
          prepareFormValues(
            formObject,
            contentLanguages,
            updateExisting,
            publicationStatus2,
            dispatch,
            keywordSets,
            secondTime
          )
        )
        .filter((preparedFormObject) => !isUndefined(preparedFormObject))

      if (!preparedFormValues.length > 0) {
        return
      }
    }

    const url =
      updateExisting && !updatingSubEvents ? `event/${formValues.id}` : 'event'

    try {
      const response = updateExisting
        ? await client.put(url, preparedFormValues)
        : await client.post(url, preparedFormValues)

      let { data } = response
      let createdEventId = data.id
      let actionName = updateExisting ? 'update' : 'create'

      // if data is an array, then we can assume that we created/updated sub events.
      // use the first item to get the publication status and super event ID.
      if (Array.isArray(data)) {
        ;[data] = data
        createdEventId = getSuperEventId(data)
      }

      // flash message is shown according to the actionName value
      if (
        data.publication_status === PUBLICATION_STATUS.PUBLIC &&
        data.publication_status !== formValues.publication_status
      ) {
        actionName = EVENT_CREATION.PUBLISH
      } else if (data.publication_status === PUBLICATION_STATUS.PUBLIC) {
        actionName = EVENT_CREATION.SAVE_PUBLIC
      } else if (data.publication_status === PUBLICATION_STATUS.DRAFT) {
        actionName = EVENT_CREATION.SAVE_DRAFT
      }

      // handle sub events if we created/updated a recurring event
      if (
        !isNil(data) &&
        data.super_event_type === SUPER_EVENT_TYPE_RECURRING &&
        !secondTime
      ) {
        isLast = false
        sendRecurringData(
          formValues,
          updateExisting,
          publicationStatus2,
          data['@id'],
          dispatch
        )
      }
      if (isLast) dispatch(sendDataComplete(createdEventId, data, actionName))
    } catch (error) {
      preliminaryErrorHandler(error, dispatch)
    }
  }

/**
 * @param {import('../store/editor').EditorValues} formValues Form data
 * @param {boolean} updateExisting Whether we're updating an existing event
 * @param {'draft' | 'public'} publicationStatus Publication status
 * @param {import('../types/apiTypes').APIEvent[]} subEvents Sub event data
 * @param {boolean?} updatingSubEvents Whether we're updating sub events
 */
export const sendRecurringData = (
  formValues,
  updateExisting,
  publicationStatus,
  superEventUrl,
  dispatch
) => {
  // this tells the executeSendRequest method whether we're updating sub events
  const splittedSubEvents = splitSubEvents(formValues.sub_events)

  let subEventsToPost =
    Object.keys(splittedSubEvents.newSubEvents).length > 0 &&
    createSubEventsFromFormValues(
      { ...formValues, sub_events: splittedSubEvents.newSubEvents },
      superEventUrl
    )

  let subEventsToPut =
    updateExisting &&
    Object.keys(splittedSubEvents.oldSubEvents).length > 0 &&
    updateSubEventsFromFormValues(formValues, splittedSubEvents.oldSubEvents)

  const deletableSubEvents = splittedSubEvents.oldSubEvents

  for (const key in deletableSubEvents) {
    const subEvent = deletableSubEvents[key]
    if (subEvent.markAsDeleted) {
      // remove the event from subEventsToSend - list, because it will be posted later to API.
      subEventsToPost =
        subEventsToPost &&
        subEventsToPost.filter(
          (subEventToSend) => subEventToSend.id !== subEvent.id
        )
      subEventsToPut =
        subEventsToPut &&
        subEventsToPut.filter(
          (subEventToSend) => subEventToSend.id !== subEvent.id
        )
      deleteEvent(subEvent)
    }
  }

  if (subEventsToPut && subEventsToPut.length > 0)
    dispatch(
      executeSendRequest(
        subEventsToPut,
        true,
        publicationStatus,
        null,
        true,
        true,
        !(subEventsToPost && subEventsToPost.length > 0)
      )
    )

  if (subEventsToPost && subEventsToPost.length > 0) {
    dispatch(
      executeSendRequest(
        subEventsToPost,
        false,
        publicationStatus,
        null,
        false,
        true
      )
    )
  }
}

// Receive topic, place, and audience keywords

/** @param {import('../types/apiTypes').KeywordSet[]} keywordSets */
export const receiveKeywordSets = (keywordSets) => {
  localStorage.setItem('KEYWORDSETS', JSON.stringify(keywordSets))

  return {
    type: constants.EDITOR_RECEIVE_KEYWORDSETS,
    keywordSets,
  }
}

// Fetch keyword sets
export const fetchKeywordSets = () => async (dispatch) => {
  try {
    const response = await client.get('keyword_set', { include: 'keywords' })

    /** @type {import('../types/apiTypes').KeywordSet[]} */
    const keywordSets = response.data.data

    dispatch(receiveKeywordSets(keywordSets))
  } catch (e) {
    throw new Error(e)
  }
}

// Receive languages
export const receiveLanguages = (languages) => {
  localStorage.setItem('LANGUAGES', JSON.stringify(languages))

  return {
    type: constants.EDITOR_RECEIVE_LANGUAGES,
    languages,
  }
}

// Fetch languages
export const fetchLanguages = () => async (dispatch) => {
  try {
    const response = await client.get('language')
    const languages = response.data.data

    dispatch(receiveLanguages(languages))
  } catch (e) {
    throw new Error(e)
  }
}

export const setEventForEditing = (eventData) => (dispatch, getState) => {
  const { keywordSets } = getState().editor
  dispatch({
    type: constants.RECEIVE_EVENT_FOR_EDITING,
    event: eventData,
    keywordSets,
  })
  dispatch(setLanguages(getContentLanguages(eventData)))
}

export function setEditorAuthFlashMsg() {
  return (dispatch, getState) => {
    const { router, user } = getState()
    const pathName = router?.location?.pathname
    const isEditorRoute = ['/event/update/', '/event/create/'].some((path) =>
      pathName.includes(path)
    )

    if (isEditorRoute) {
      isNil(user)
        ? dispatch(
            setFlashMsg('editor-authorization-required', 'error', {
              sticky: true,
            })
          )
        : dispatch(clearFlashMsg())
    }
  }
}
