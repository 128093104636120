import './Header.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
// Material-ui Components
import { Button, Drawer, Hidden, Toolbar } from '@material-ui/core'
import { Add, Menu } from '@material-ui/icons'

import { NavLinks } from './NavLinks'
import { HeaderLogo } from './HeaderLogo'
import { EnvironmentBanner } from './EnvironmentBanner'
import { UserActions } from './UserActions'
import { RootState } from '../../store'
import { constants } from '../../constants'

export const HeaderBar = (): React.ReactNode => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isSuperuser, setIsSuperuser] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)

  useEffect(
    () => setIsAdmin(user?.userType === constants.USER_TYPE.ADMIN),
    [user, setIsAdmin]
  )

  useEffect(() => setIsSuperuser(user?.superuser), [user, setIsSuperuser])

  const routerPush = (url: string): void => {
    dispatch(push(url))
  }

  const [navBarOpen, setNavBarOpen] = useState(false)

  const toMainPage = (): void => routerPush('/')
  const toSearchPage = (): void => routerPush('/search')
  const toHelpPage = (): void => routerPush('/help')
  const toUserInformationPage = (): void => routerPush('/user')
  const toUserManagementPage = (): void => routerPush('/users')
  const toModerationPage = (): void => routerPush('/moderation')

  const isInsideForm = location.pathname.startsWith('/event/create/new')

  const toggleNavbar = (): void => setNavBarOpen(!navBarOpen)

  const navigateAndCloseNavbar = (navigate: () => void) => () => {
    navigate()
    toggleNavbar()
  }

  return (
    <div className="main-navbar">
      <Toolbar className="helsinki-bar">
        <HeaderLogo />
        <EnvironmentBanner />
        <UserActions user={user} />
      </Toolbar>

      <Toolbar className="linked-events-bar">
        <div
          className="linked-events-bar__logo"
          onClick={() => routerPush('/')}
        >
          <FormattedMessage id="linked-events" />
        </div>
        <div className="linked-events-bar__links">
          <Hidden mdDown>
            <div className="linked-events-bar__links__list">
              <NavLinks
                isLoggedIn={!!user}
                isAdmin={isAdmin}
                isSuperuser={isSuperuser}
                toMainPage={toMainPage}
                toSearchPage={toSearchPage}
                toHelpPage={toHelpPage}
                toUserInformationPage={toUserInformationPage}
                toUserManagementPage={toUserManagementPage}
                toModerationPage={toModerationPage}
              />
            </div>
          </Hidden>
          <div />
          <div className="linked-events-bar__links__mobile">
            {!isInsideForm && (
              <Button
                variant="outlined"
                className="linked-events-bar__links__create-events"
                onClick={() => routerPush('/event/create/new')}
                startIcon={<Add />}
              >
                <FormattedMessage id="create-events" />
              </Button>
            )}
            <Hidden lgUp>
              <Menu
                className="linked-events-bar__icon"
                onClick={toggleNavbar}
              />
              <Drawer
                anchor="right"
                open={navBarOpen}
                ModalProps={{ onBackdropClick: toggleNavbar }}
              >
                <div className="menu-drawer-mobile">
                  <NavLinks
                    isLoggedIn={!!user}
                    isAdmin={isAdmin}
                    isSuperuser={isSuperuser}
                    toMainPage={navigateAndCloseNavbar(toMainPage)}
                    toSearchPage={navigateAndCloseNavbar(toSearchPage)}
                    toHelpPage={navigateAndCloseNavbar(toHelpPage)}
                    toUserInformationPage={navigateAndCloseNavbar(
                      toUserInformationPage
                    )}
                    toUserManagementPage={navigateAndCloseNavbar(
                      toUserManagementPage
                    )}
                    toModerationPage={navigateAndCloseNavbar(toModerationPage)}
                  />
                </div>
              </Drawer>
            </Hidden>
          </div>
        </div>
      </Toolbar>
    </div>
  )
}
