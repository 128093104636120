import './Header.scss'

import React from 'react'
import { FormattedMessage } from 'react-intl'

// Material-ui Components
import { Button, makeStyles } from '@material-ui/core'
import { features } from '../../utils/featureFlags'

interface NavLinksProps {
  isLoggedIn: boolean
  isAdmin: boolean
  isSuperuser: boolean
  toMainPage: () => void
  toSearchPage: () => void
  toHelpPage: () => void
  toUserInformationPage: () => void
  toUserManagementPage: () => void
  toModerationPage: () => void
}

export const NavLinks = ({
  isLoggedIn,
  isAdmin,
  isSuperuser,
  toMainPage,
  toSearchPage,
  toHelpPage,
  toUserInformationPage,
  toUserManagementPage,
  toModerationPage,
}: NavLinksProps): React.ReactElement => {
  const isPrivilegedUser = isAdmin || isSuperuser

  const moderationStyles =
    isPrivilegedUser &&
    makeStyles((theme) => ({
      root: { color: theme.palette.primary.main },
    }))()

  return (
    <React.Fragment>
      <Button onClick={toMainPage}>
        <FormattedMessage id="events-management" />
      </Button>
      <Button onClick={toSearchPage}>
        <FormattedMessage id="search-events" />
      </Button>
      <Button onClick={toHelpPage}>
        {' '}
        <FormattedMessage id="more-info" />
      </Button>
      {features.userManagement && isLoggedIn && (
        <Button onClick={toUserInformationPage}>
          {' '}
          <FormattedMessage id="user-info-page" />
        </Button>
      )}
      {features.userManagement && isSuperuser && (
        <Button onClick={toUserManagementPage} classes={moderationStyles}>
          <FormattedMessage id="user-management-page" />
        </Button>
      )}
      {isPrivilegedUser && (
        <Button onClick={toModerationPage} classes={moderationStyles}>
          <FormattedMessage id="moderation-page" />
        </Button>
      )}
    </React.Fragment>
  )
}
