import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { HelCheckbox } from './HelCheckbox'
import { NewOffer } from './NewOffer'
import './HelOffersField.scss'
import {
  addOffer,
  deleteOffer,
  setOfferData,
  setFreeOffers,
} from '../../actions/editor'
import { HelMaterialTheme } from '../../themes/material-ui'

/* eslint-disable @typescript-eslint/unbound-method */
export class HelOffersField extends React.Component {
  static contextTypes = {
    intl: PropTypes.object,
    dispatch: PropTypes.func,
  }

  constructor(props) {
    super(props)
    let isFreeEvent = true
    if (this.props.defaultValue && this.props.defaultValue.length > 0) {
      isFreeEvent = false // we have length in defaultvalue array so we have prices -> not a free event.
    }

    this.state = {
      values: this.props.defaultValue,
      isFree: isFreeEvent,
    }

    this.onDelete = this.onDelete.bind(this)
  }

  UNSAFE_componentWillMount() {
    if (this.props.defaultValue && this.props.defaultValue.length) {
      this.setState({ values: this.props.defaultValue })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.defaultValue &&
      nextProps.defaultValue !== this.state.values
    ) {
      this.setState({ values: nextProps.defaultValue })
    }
    if (
      nextProps.defaultValue &&
      nextProps.defaultValue[0] &&
      this.state.isFree !== nextProps.defaultValue[0].is_free
    ) {
      this.setState({ isFree: nextProps.defaultValue[0].is_free })
    }
    // Mark the event as free if there are no offers
    if (!nextProps.defaultValue || nextProps.defaultValue.length === 0) {
      this.setState({ isFree: true })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsFree(e, value) {
    if (!this.props.defaultValue || !this.props.defaultValue.length) {
      this.addNewOffer()
      this.context.dispatch(
        setOfferData({ 0: { is_free: !this.state.isFree } }, 0)
      )
    } else {
      this.context.dispatch(setFreeOffers(!this.state.isFree))
    }
    this.setState({ isFree: !this.state.isFree })
  }

  addNewOffer() {
    const obj = {
      is_free: this.state.isFree,
    }
    this.context.dispatch(addOffer(obj))
  }

  onDelete(offerKey) {
    if (this.props.defaultValue && this.props.defaultValue.length === 1) {
      // We're deleting the last offer item so let's make this a free event
      this.context.dispatch(setFreeOffers(!this.state.isFree))
      this.setState((state) => ({ isFree: !state.isFree }))
    } else {
      this.context.dispatch(deleteOffer(offerKey))
    }
  }

  generateOffers(offers) {
    const newOffers = []

    for (const key in offers) {
      // eslint-disable-next-line no-prototype-builtins
      if (offers.hasOwnProperty(key) && !this.state.isFree) {
        newOffers.push(
          <NewOffer
            key={key}
            offerKey={key}
            defaultValue={this.props.defaultValue[key]}
            validationErrors={this.props.validationErrors}
            languages={this.props.languages}
            isFree={this.state.isFree}
            onDelete={this.onDelete}
          />
        )
      }
    }
    return newOffers
  }

  render() {
    const offerDetails = this.generateOffers(this.props.defaultValue)

    return (
      <div className="offers-field">
        <HelCheckbox
          defaultChecked={this.state.isFree}
          label={<FormattedMessage id="is-free" />}
          onChange={(e, v) => this.setIsFree(e, v)}
        />
        <div className="offers">{offerDetails}</div>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={this.state.isFree}
          style={{ marginTop: HelMaterialTheme.spacing(2) }}
          startIcon={<Add />}
          onClick={() => this.addNewOffer()}
        >
          <FormattedMessage id="event-add-price" />
        </Button>
      </div>
    )
  }
}
/* eslint-enable @typescript-eslint/unbound-method */

HelOffersField.propTypes = {
  defaultValue: PropTypes.array,
  validationErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  languages: PropTypes.array,
}
