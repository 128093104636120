import '../EventDetails.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { NoValue } from './NoValue'
import { TextValue } from './TextValue'

export const VideoValue = ({ values }) => {
  if (!values || values.length === 0) {
    return <NoValue labelKey={'event-video'} />
  }

  return (
    <div className={'video-item'}>
      {values.map((item, index) => (
        <div key={`video-item-${index}`} className={'video-item--container'}>
          {Object.entries(item).map(([key, value]) => (
            <TextValue
              key={`video-value-${key}`}
              labelKey={`event-video-${key}`}
              value={value}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

VideoValue.propTypes = {
  values: PropTypes.array,
}
