import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import UsersTable, { UsersTableColumn } from './UsersTable'
import { useClient } from '../../api/clientContext'
import { Organization, OrganizationAccessRequest } from '../../types/apiTypes'
import { AxiosInstance, AxiosResponse } from 'axios'
import { debounce } from 'lodash'
import { constants } from '../../constants'

export type UserAccessInfo = {
  userName: string
  email?: string
  name?: string
  organizations: string
}

export type OrganizationAccessRequestInfo = {
  requester: string
  request_received: string
  requested_organization: string
  id: string
}

const mapDataToUserAccessInfo = (
  obj: Record<string, string> & { admin_organizations: string[] },
  organizationData: Organization[]
): UserAccessInfo => ({
  userName: obj.username,
  email: obj.email ? obj.email : undefined,
  name: obj.display_name ? obj.display_name : undefined,
  organizations:
    obj.admin_organizations.length > 0
      ? obj.admin_organizations
          .map(
            (id) =>
              organizationData.find((org: Organization) => org.id === id)?.name
          )
          .join(', ')
      : '',
})

const mapDataToOARInfo = (
  data: OrganizationAccessRequest,
  organizationData: Organization[]
): OrganizationAccessRequestInfo => ({
  requester: data.requester.split(' -')[0],
  request_received: data.request_date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('.'),
  requested_organization:
    organizationData.find((org) => org.id === data.organization)?.name ?? '',
  id: data.id,
})

const usersTableColumns: UsersTableColumn[] = [
  { id: 'email', translationId: 'email' },
  { id: 'name', translationId: 'name' },
  { id: 'organizations', translationId: 'organizations' },
]

const oarsTableColumns: UsersTableColumn[] = [
  { id: 'requester', translationId: 'name' },
  { id: 'request_received', translationId: 'request-received' },
  { id: 'requested_organization', translationId: 'requested-organization' },
]

export const getRest = (
  client: AxiosInstance,
  id: string,
  count: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>>[] => {
  if (count < constants.pageSize) return []
  const pageNumbers = [...Array(Math.ceil(count / constants.pageSize)).keys()]
    .map((i) => i + 1)
    .slice(1)
  return pageNumbers.map((n) =>
    client.get(id, { params: { page_size: constants.pageSize, page: n } })
  )
}

const UserManagementPage: React.FC = () => {
  const [loadingOARs, setLoadingOARs] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [dirtyState, setDirtyState] = useState<boolean | undefined>(undefined)
  const [userInfoData, setUserInfoData] = useState<UserAccessInfo[]>([])
  const [oarInfoData, setOarInfoData] = useState<
    OrganizationAccessRequestInfo[]
  >([])
  const intl = useIntl()
  const client = useClient()

  const fetch = useMemo(() => {
    const fetchData = async (): Promise<void> => {
      const [userFirstResponse, organizationFirstResponse, oarFirstResponse] =
        await Promise.all([
          client.get('user', { params: { page_size: constants.pageSize } }),
          client.get('organization', {
            params: { page_size: constants.pageSize },
          }),
          client.get('organizationaccessrequest', {
            params: { page_size: constants.pageSize },
          }),
        ])

      const restResponse = await Promise.all(
        [
          getRest(client, 'user', userFirstResponse.data.meta.count),
          getRest(
            client,
            'organization',
            organizationFirstResponse.data.meta.count
          ),
          getRest(
            client,
            'organizationaccessrequest',
            oarFirstResponse.data.meta.count
          ),
        ].flat()
      )

      const userData = [
        ...userFirstResponse.data.data,
        ...restResponse
          .filter((response) => 'username' in response.data.data[0])
          .flatMap((response) => response?.data.data),
      ]

      const organizationData = [
        ...organizationFirstResponse.data.data,
        ...restResponse
          .filter(
            (response) => response.data.data[0]['@type'] === 'Organization'
          )
          .flatMap((response) => response.data.data),
      ]

      const oarData = [
        ...oarFirstResponse.data.data,
        ...restResponse
          .filter(
            (response) =>
              response.data.data[0]['@type'] === 'OrganizationAccessRequest'
          )
          .flatMap((response) => response.data.data),
      ]

      if (userData && Array.isArray(userData))
        setUserInfoData(
          userData.map((obj) => mapDataToUserAccessInfo(obj, organizationData))
        )

      if (oarData && Array.isArray(oarData))
        setOarInfoData(
          oarData
            .filter(
              (oar: OrganizationAccessRequest) => oar.request_status === 1
            )
            .map((oar: OrganizationAccessRequest) =>
              mapDataToOARInfo(oar, organizationData)
            )
        )

      setLoadingOARs(false)
      setLoadingUsers(false)
    }

    return debounce(fetchData, 3000)
  }, [client])

  useEffect(() => {
    if (fetch) fetch()
  }, [fetch, dirtyState])

  return (
    <div className="container">
      <h1>{intl.formatMessage({ id: 'user-management-page' })}</h1>
      <UsersTable
        data={oarInfoData}
        loading={loadingOARs}
        columns={oarsTableColumns}
        headerId={'user-management-oars'}
        dirtyState={dirtyState}
        setDirtyState={setDirtyState}
        setLoadingUsers={setLoadingUsers}
        setTableData={setOarInfoData}
      />
      <UsersTable
        data={userInfoData}
        loading={loadingUsers}
        columns={usersTableColumns}
        headerId={'user-management-users'}
      />
    </div>
  )
}

export default UserManagementPage
