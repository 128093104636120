import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { HobbyTopicsCheckboxGroup } from './HobbyTopicsCheckboxGroup'
import { LibraryEventCheckboxGroup } from './LibraryEventCheckboxGroup'
import { CultureAndOthersCheckboxGroup } from './CultureAndOthersCheckboxGroup'
import { HelLabeledCheckboxGroup } from '../../HelFormFields'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { selectYsoMapper } from '../../../store/editor/keywords'
import { setData } from '../../../actions/editor'
import { ysoShortCodeMapping } from '../../../utils/formDataMapping'
import { useHobbyCategories } from '../../../store/editor/keywords'
import { SideField } from '../SideField'

const useHobbyCategoriesOptimization = (): void => {
  // Harrastuhaku optimization: use hobbyCategories hook in order to fetch the data
  // from the API (or local storage) already during the initial render.{
  const hobbyCategories = useHobbyCategories()
  React.useEffect(() => {}, [hobbyCategories])
}

/**
 * Options do not correspond 1:1 with the visible checkbox values. This is their mapping.
 *
 * |                       | harrastus | helmet | tapahtuma | espoo.fi |
 * |-----------------------|-----------|--------|-----------|----------|
 * | `harrastushaku`       | yes | ?   | no  | yes |
 * | `kirjastotapahtuma`   | no  | yes | ?   | yes |
 * | `kulttuuritapahtumat` | no | no   | yes | yes |
 * | `muut-tapahtumat`     | no | no   | no  | yes |
 */
export const Topics: React.FC = () => {
  // TODO: Should selected calendar be sent to API?
  const [selectedValues, setSelectedValues] = React.useState<string[]>([])

  const topicsFromState = useSelector(
    (state: RootState) => state.editor.values.keywords
  )
  const [notHydrated, setHydrated] = React.useState(true)
  const [shouldRenderHarrastushaku, setShouldRenderHarrastushaku] =
    React.useState<boolean>(false)
  useHobbyCategoriesOptimization()

  const [shouldRenderKirjastotapahtumat, setShouldRenderKirjastotapahtumat] =
    React.useState<boolean>(false)
  const [shouldRenderKulttuuriTapahtumat, setShouldRenderKulttuuriTapahtumat] =
    React.useState<boolean>(false)
  const [shouldRenderMuutTapahtumat, setShouldRenderMuutTapahtumat] =
    React.useState<boolean>(false)

  const dispatch = useDispatch()

  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors.keywords
  )

  const keywordMapping = useSelector(selectYsoMapper)
  const intl = useIntl()

  const TopicTip = (): JSX.Element => (
    <SideField>
      <p className="tip">
        {intl.formatMessage({ id: 'editor-tip-topic-info' })}
      </p>
    </SideField>
  )

  const updateValues = (newValues: string[]): void => {
    setShouldRenderHarrastushaku(
      newValues.includes(keywordMapping.harrastushaku ?? 'harrastushaku')
    )
    setShouldRenderKirjastotapahtumat(
      newValues.includes(
        keywordMapping.kirjastotapahtumat ?? 'kirjastotapahtumat'
      )
    )
    setShouldRenderKulttuuriTapahtumat(
      newValues.includes(
        keywordMapping.kulttuuritapahtumat ?? 'kulttuuritapahtuma'
      )
    )

    setShouldRenderMuutTapahtumat(
      newValues.includes(keywordMapping.muuttapahtumat ?? 'muuttapahtumat')
    )

    setSelectedValues(newValues)

    // remove placeholder values from the list, because
    // state-wise they are not valid values.
    //
    // There are two reason for this:
    // 1. muuttapahtumat does not have YSO - code, backend-PoV it's like it's never selected.
    // 2. Someone selects an option before UI receives the mapping values. Such values should not even be rendered.
    newValues = newValues.filter((value) => !(value in ysoShortCodeMapping))

    dispatch(setData({ keywords: newValues }))
  }

  useEffect(() => {
    if (topicsFromState && notHydrated) {
      setHydrated(false)
      if (
        topicsFromState.includes(
          keywordMapping.harrastushaku ?? 'harrastushaku'
        ) ||
        topicsFromState.includes(
          keywordMapping.kirjastotapahtumat ?? 'kirjastotapahtumat'
        ) ||
        topicsFromState.includes(
          keywordMapping.kulttuuritapahtumat ?? 'kulttuuritapahtumat'
        )
      ) {
        updateValues([...topicsFromState, ...selectedValues])
      } else {
        updateValues([
          ...selectedValues,
          ...topicsFromState,
          keywordMapping.muuttapahtumat ?? 'muuttapahtumat',
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notHydrated, topicsFromState])

  return (
    <>
      {/* TODO: Tooltip */}
      <div className="row">
        <HelLabeledCheckboxGroup
          required={false}
          groupLabel={intl.formatMessage({ id: 'event-calendar-label' })}
          selectedValues={selectedValues}
          itemClassName="col-sm-12"
          options={[
            {
              // at calendars: harrastushaku + espoo.fi (+ helmet?)
              label: intl.formatMessage({ id: 'event-topics-option-hobby' }),
              value: keywordMapping.harrastushaku ?? 'harrastushaku', // https://localhost:8080/v1/keyword/yso:p2901/
            },
            {
              // at calendars: helmet + espoo.fi + (tapahtumakalenteri?)
              label: intl.formatMessage({ id: 'event-topics-option-library' }),
              value: keywordMapping.kirjastotapahtumat ?? 'kirjastotapahtumat', // https://localhost:8080/v1/keyword/yso:p2787/
            },
            {
              // at calendars: tapahtumakalenteri
              label: intl.formatMessage({ id: 'event-topics-option-culture' }),
              value:
                keywordMapping.kulttuuritapahtumat ?? 'kulttuuritapahtumat', // https://localhost:8080/v1/keyword/yso:p360/
            },
            {
              // at calendars: espoo.fi
              label: intl.formatMessage({ id: 'event-topics-option-other' }),
              value: keywordMapping.muuttapahtumat ?? 'muuttapahtumat', // undefined
            },
          ]}
          handleChange={updateValues}
          validationErrors={validationErrors}
        />
        <SideField>
          <div className="tip">
            <p>{intl.formatMessage({ id: 'editor-tip-category-info-one' })}</p>
            <p>
              {intl.formatMessage({ id: 'editor-tip-category-info-hobby' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'editor-tip-category-info-library' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'editor-tip-category-info-culture' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'editor-tip-category-info-other' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'editor-tip-category-info-explain' })}
            </p>
          </div>
        </SideField>
      </div>
      {shouldRenderHarrastushaku && (
        <div className="row">
          <HobbyTopicsCheckboxGroup />
          <TopicTip />
        </div>
      )}
      {shouldRenderKirjastotapahtumat && (
        <div className="row">
          <LibraryEventCheckboxGroup />
          {!shouldRenderHarrastushaku && <TopicTip />}
        </div>
      )}
      {(shouldRenderKulttuuriTapahtumat || shouldRenderMuutTapahtumat) && (
        <div className="row">
          <CultureAndOthersCheckboxGroup />
          {!shouldRenderHarrastushaku && !shouldRenderKirjastotapahtumat && (
            <TopicTip />
          )}
        </div>
      )}
    </>
  )
}
